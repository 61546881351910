import './App.css'
import back from './img/back.png'
import React, {useEffect, useRef, useState} from "react";
import {motion} from "framer-motion";

// Import the image you want to show
import popupImage from './img/joke-jk.gif';
import ParticlesBackground from "./Components/ParticlesBackground";
import MainPage from "./MainPage";

function App() {

    return (
        <>
            <ParticlesBackground/>
            <MainPage/>
        </>
    );
}

export default App;
