import React from "react";
import ParticlesComponent from "./particles";

const ParticlesBackground = () => {
    return (
        <ParticlesComponent id='particles'>

        </ParticlesComponent>
    )
}

export default ParticlesBackground;