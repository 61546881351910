import './App.css'
import {motion} from "framer-motion";
import popupImage from "./img/joke-jk.gif";
import back from "./img/back.png";
import React, {useEffect, useRef, useState} from "react";
import ShareButton from "./Components/Share/Share";

function MainPage() {
    const [time, setTime] = useState(120);
    const [showPopup, setShowPopup] = useState(false);
    const timerRef = useRef(null);
    const [showImage, setShowImage] = useState(false); // Add this line

    const startTimer = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        setTime(120); //timer's time
        timerRef.current = setInterval(() => {
            setTime(prevTime => prevTime > 0 ? prevTime - 1 : 0);
        }, 1000);
    };

    useEffect(() => {
        startTimer();
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (time === 0) {
            setShowPopup(true);
        }
    }, [time]);

    const resetTimer = () => {
        startTimer();
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    return (
        <div id='app' onMouseMove={resetTimer} onKeyDown={resetTimer} tabIndex="0">
            <div id='main_page'>
                <h1>
                    WELCOME TO THE
                    <br/>
                    <orange>FREE PRIZE</orange>
                    GIVEAWAY!
                </h1>
                <p>Just wait 2 minutes without any cursor movement, and then<br/> claim your surprise prize below</p>

                {!showPopup &&
                    <>
                        <orange_timer>
                            {formatTime(time)}
                        </orange_timer>
                        <ShareButton/>
                    </>

                }
                {showPopup && <motion.div
                    className="box"
                    whileHover={{scale: 1.1}}
                    transition={{type: "spring", stiffness: 400, damping: 100}}
                >
                    <button className='claim_btn' onClick={() => setShowImage(true)}>GET THE PRIZE</button>
                </motion.div>
                }
            </div>
            {showImage && <img src={popupImage} className="pop-image"/>}
            <img src={back} className='back'/>
        </div>
    )
}

export default MainPage;