import React from 'react';
import png1 from '../../img/share.png'
import './Share.css'
const ShareButton = () => {
    const shareData = {
        title: 'Don\'t move for 2 minutes and claim your reward!',
        text: 'Share it with your friends, and let the claim their prize!',
        url: 'https://www.funprize.org',
    }

    const handleShare = async () => {
        try {
            await navigator.share(shareData)
        } catch(err) {
            console.error('Share failed:', err.message);
        }
    }

    return (
        <>
            <button onClick={handleShare} className='button_share'>
                <p className='share_p'>Share it with your friends, and let the claim their prize!</p>
                <div>
                    <img src={png1} className='share'/>
                </div>

            </button>
        </>
    );
}

export default ShareButton;
